<template>
    <div class="social-container">
        <div class="social-title">募集職種</div>
        <div class="positions-container row">
            <div @click="modalHandler(index)" class="col-5 position-container" v-for="(item, index) in recruitTextConfig.Social.positionData" :key="index">
                <div style="display:flex;align-items: center;
                                justify-content:space-between">
                    <div style="display:flex;">
                        <div class="position-title-img"></div>
                        <div class="position-title-text">{{item.title}}</div>
                    </div>
                    <div class="position-title-num">{{item.num}}</div>
                </div>
                <div class="position-desc">
                    <div>応募資格：</div>
                    <div v-for="(condition, index) in item.conditions" :key="index">
                        {{condition}}
                    </div>
                </div>
            </div>
        </div>
        <div style="margin-bottom:130px;">
            <div style="padding-bottom:30px;" class="social-title dash-border">給与待遇</div>
            <div class="given-condition dash-border row" v-for="(item, index) in recruitTextConfig.Social.givenData" :key="index">
                <div class="col-2 given-title">{{item.title}}</div>
                <!-- <div class="col-10 given-desc">{{item.desc}}</div> -->
                <div class="col-10 given-desc" v-html="item.desc"></div>
            </div>
        </div>
        <div class="modal fade" id="modal" tabindex="-1" role="dialog" aria-labelledby="modal" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 style="color:rgba(51,51,51,1);" class="modal-title" id="modalLabel">職務の詳細</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="modal-title col-3">职位名称：</div>
                        <div class="modal-text col-8">{{recruitTextConfig.Social.positionData[clickedPosition].title}}</div>
                    </div>
                    <div style="margin-top:20px" class="row">
                        <div class="modal-title col-3">招聘人数：</div>
                        <div class="modal-text col-8">{{recruitTextConfig.Social.positionData[clickedPosition].num}}</div>
                    </div>
                    <div style="margin-top:20px;margin-bottom:20px;" class="row">
                        <div class="modal-title col-3">任职资格：</div>
                        <div class="modal-text col-8">
                            <div v-for="(item, index) in recruitTextConfig.Social.positionData[clickedPosition].conditions" :key="index">
                                {{item}}
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { recruitTextConfig } from "@/config/Recruit"

export default {
    data(){
        return {
            clickedPosition:0,
            recruitTextConfig:recruitTextConfig,
        }
    },
    methods:{
        modalHandler(index){
            this.clickedPosition = index
            $('.modal').modal('show')
        }
    }
}
</script>
<style>
@media screen and (min-width:768px) and (max-width:1920px){
    .social-title{
        margin-top:40px;
        text-align:left;
        font-size:calc(100vw/1920*24);
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*33);;
    }
    .social-container{
        padding:0 calc(100vw/1920*360)
    }
    .dash-border{
        border-bottom: 1px dashed #DDDDDD;
    }
    .positions-container{
        margin-top: 15px;
    }
    .position-container{
        margin: 15px 20px;
        border:1px solid #DDDDDD;
        padding: calc(100vw/1920*20);
    }
    .position-container:hover{
        box-shadow:0px 0px 15px 0px rgba(0,0,0,0.15);
        border:1px solid rgba(2,98,255,1);
        transition: all 0.5s;
        cursor: pointer;
    }
    .given-condition{
        padding: 32px 0;
        text-align: left;
    }
    .position-title-img{
        width: calc(100vw/1920*18);
        height: calc(100vw/1920*18);
        background: #FE8F03;
    }
    .position-title-text{
        margin-left: calc(100vw/1920*20);
        font-size:calc(100vw/1920*18);
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*25);
    }
    .position-title-num{
        font-size: calc(100vw/1920*18);
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(153,153,153,1);
        line-height:calc(100vw/1920*25);
    }
    .position-desc{
        margin-top: calc(100vw/1920*20);
    }
    .position-desc div{
        font-size:calc(100vw/1920*16);
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:calc(100vw/1920*30);
        text-align: left;
    }
    .given-title{
        font-size:calc(100vw/1920*18);
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*25);
    }
    .given-desc{
        font-size:calc(100vw/1920*16);
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:calc(100vw/1920*22);
    }
    .modal-title{
        font-size:calc(100vw/1920*14);
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(153,153,153,1);
        line-height:calc(100vw/1920*20);
    }
    .modal-text{
        font-size:calc(100vw/1920*14);
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*28);
        text-align: left;
    }
}
@media screen and (max-width:768px){
    .social-title{
        margin-top:40px;
        text-align:left;
        font-size:12px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:14px;
    }
    .social-container{
        padding:20px;
    }
    .dash-border{
        font-size: 14px;
        border-bottom: 1px dashed #DDDDDD;
    }
    .positions-container{
        margin-top: 15px;
    }
    .position-container{
        min-width: 90%;
        margin: 15px 20px;
        border:1px solid #DDDDDD;
        padding: calc(100vw/1920*20);
    }
    .position-container:hover{
        box-shadow:0px 0px 15px 0px rgba(0,0,0,0.15);
        border:1px solid rgba(2,98,255,1);
        transition: all 0.5s;
        cursor: pointer;
    }
    .given-condition{
        padding: 32px 0;
        text-align: left;
    }
    .position-title-img{
        width: 4px;
        height: 4px;
        background: #FE8F03;
    }
    .position-title-text{
        margin-left: calc(100vw/1920*20);
        font-size:12px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:14px;
    }
    .position-title-num{
        font-size: 12px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(153,153,153,1);
        line-height:14px;
    }
    .position-desc{
        margin-top: 5px;
    }
    .position-desc div{
        font-size:12px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:14px;
        text-align: left;
    }
    .given-title{
        min-width: 200px;
        min-height: 40px;
        font-size:14px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:16px;
    }
    .given-desc{
        font-size:12px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:14px;
    }
    .modal-title{
        padding: 0;
        font-size:12px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(153,153,153,1);
        line-height:14px;
    }
    .modal-text{
        font-size:12px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(51,51,51,1);
        line-height:14px;
        text-align: left;
    }
}
@media screen and (min-width:1920px){
    .social-title{
        margin-top:40px;
        text-align:left;
        font-size:24px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:33px;;
    }
    .social-container{
        padding:0 360px;
    }
    .dash-border{
        border-bottom: 1px dashed #DDDDDD;
    }
    .positions-container{
        margin-top: 15px;
    }
    .position-container{
        margin: 15px 20px;
        border:1px solid #DDDDDD;
        padding: 20px;
    }
    .position-container:hover{
        box-shadow:0px 0px 15px 0px rgba(0,0,0,0.15);
        border:1px solid rgba(2,98,255,1);
        transition: all 0.5s;
        cursor: pointer;
    }
    .given-condition{
        padding: 32px 0;
        text-align: left;
    }
    .position-title-img{
        width: 18px;
        height: 18px;
        background: #FE8F03;
    }
    .position-title-text{
        margin-left: 20px;
        font-size:18px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:25px;
    }
    .position-title-num{
        font-size: 18px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(153,153,153,1);
        line-height:25px;
    }
    .position-desc{
        margin-top: 20px;
    }
    .position-desc div{
        font-size:16px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:30px;
        text-align: left;
    }
    .given-title{
        font-size:18px;
        font-family:PingFangSC-Medium,PingFang SC;
        font-weight:500;
        color:rgba(51,51,51,1);
        line-height:25px;
    }
    .given-desc{
        font-size:16px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(102,102,102,1);
        line-height:22px;
    }
    .modal-title{
        font-size:14px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(153,153,153,1);
        line-height:20px;
    }
    .modal-text{
        font-size:14px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color:rgba(51,51,51,1);
        line-height:28px;
        text-align: left;
    }
}
</style>